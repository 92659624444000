// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-entrusted-development-js": () => import("./../../../src/pages/services/entrusted-development.js" /* webpackChunkName: "component---src-pages-services-entrusted-development-js" */),
  "component---src-pages-services-programming-school-js": () => import("./../../../src/pages/services/programming-school.js" /* webpackChunkName: "component---src-pages-services-programming-school-js" */),
  "component---src-pages-submitted-js": () => import("./../../../src/pages/submitted.js" /* webpackChunkName: "component---src-pages-submitted-js" */)
}

